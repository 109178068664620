import { Session } from 'Utils';

class RoutesManager {
  constructor(router) {

    this.router = router;
    this.buildRoutes = this.buildRoutes.bind(this);

    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.isBoss = this.isBoss.bind(this);
    this.isSoc = this.isSoc.bind(this);


    this.publicRoutes = {
      '/public':                                    { component: 'page-home',                         title:'SysDream CSIRT/CERT' },
      '/public/cti':                                { component: 'page-cti',                          title:'Traitement des incidents Cybersécurité' },
      '/public/maps':                               { component: 'page-maps',                         title:'Maps' },
      '/public/maps/ddosia':                        { component: 'page-map-ddosia',                   title:'NoName057(16) DDoSia' },
      '/public/mondossier/etape1':                  { component: 'page-dossier-etape1',               title:'Mon dossier' },
      '/public/mondossier/etape2':                  { component: 'page-dossier-etape2',               title:'Mon dossier' },
      '/public/mondossier/etape3':                  { component: 'page-dossier-etape3',               title:'Mon dossier' },
      '/public/mondossier/etape4':                  { component: 'page-dossier-etape4',               title:'Vérification de votre email' },
      '/public/mondossier/etape5':                  { component: 'page-dossier-etape5',               title:'Accès à votre dossier autorisé' },
      '/public/mondossier/consulter/:hash':         { component: 'page-dossier-consulter',            title:'Votre dossier' },
      '/public/notifications':                      { component: 'page-notifications',                title:'Notifications' },
      '/public/user/logout':                        { component: 'page-user-logout',                  title:'Déconnexion' },
      '/public/forbidden':                          { component: 'error-forbidden',                   title:'Accès refusé' },
      '/public/errors':                             { component: 'error-list',                        title:'Messages d\'erreur' },
      '/public/errors/503':                         { component: 'error-maintenance',                 title:'Service indisponible' },
      '/public/validation/email':                   { component: 'page-validation-email',             title:'Validation email' },
      '/public/validation/mobile':                  { component: 'page-validation-mobile',            title:'Validation mobile' },
      
      '/public/tests':                              { component: 'page-tests-ux',                     title:'Tests UX' },
      '/public/tests/modal_dialog':                 { component: 'page-tests-modal-dialog',           title:'Test modal-dialog' },
      '/public/tests/modal_drawer':                 { component: 'page-tests-modal-drawer',           title:'Test modal-drawer' },
      '/public/tests/reveal':                       { component: 'page-tests-reveal',                 title:'Test RevealJS' },
      '/public/tests/section_header':               { component: 'page-tests-section-header',         title:'Test section-header' },
      '/public/tests/sl_tab_group':                 { component: 'page-tests-sl-tab-group',           title:'Test sl-tab-group' },
      '/public/tests/tab_group':                    { component: 'page-tests-tab-group',              title:'Test tab-group' },
      '/public/tests/i18n':                         { component: 'page-tests-i18n',                   title:'Test i18n' },
      '/public/tests/select_permissions':           { component: 'page-tests-select-permissions',     title:'Test select-permissions' },
      '/public/tests/pretty_json':                  { component: 'page-tests-pretty-json',            title:'Test pretty-json' },
      '/public/tests/context_menu':                 { component: 'page-tests-context-menu',           title:'Test context-menu' },
      '/public/tests/apex_charts':                  { component: 'page-tests-apexcharts',             title:'Test apexcharts' },
      '/public/tests/cherry_editor':                { component: 'page-tests-cherry-editor',          title:'Test cherry-editor' },
    };

    this.authenticatedRoutes = {
      '/private':                                   { component: 'page-user-home',                    title:'Mon espace',                   action:this.isAuthenticated },
      '/private/user/account':                      { component: 'page-user-account',                 title:'Mon compte',                   action:this.isAuthenticated },

      '/private/doc':                               { component: 'page-doc',                          title:'Documentations',               action:this.isAuthenticated },
      '/private/doc/api':                           { component: 'page-doc-api',                      title:'API',                          action:this.isAuthenticated },
      '/private/doc/faq':                           { component: 'page-doc-faq',                      title:'FAQ' },
    }

    this.adminRoutes = {
      '/private/admin':                             { component: 'page-admin-home',                   title:'Administration',               action:this.isBoss },
      '/private/admin/customers':                   { component: 'page-admin-customers',              title:'Gestion clients',              action:this.isBoss },
      '/private/admin/customers/tree':              { component: 'page-admin-customers-tree',         title:'Arborescence',                 action:this.isBoss },

      '/private/admin/sys/languages':               { component: 'page-languages',                    title:'Gestion des langues',          action:this.isBoss },
      '/private/admin/sys/languages/list':          { component: 'page-languages-list',               title:'Langues du site',              action:this.isBoss },
      '/private/admin/sys/templates':               { component: 'page-tpl',                          title:'Modèles',                      action:this.isBoss },
      '/private/admin/sys/templates/email':         { component: 'page-tpl-email',                    title:'Email templates',              action:this.isBoss },
      '/private/admin/sys/templates/sms':           { component: 'page-tpl-sms',                      title:'SMS templates',                action:this.isBoss },
      '/private/admin/sys/languages/translate':     { component: 'page-mass-translate',               title:'Traduction',                   action:this.isBoss },
      '/private/admin/sys/sessions':                { component: 'page-sessions',                     title:'Sessions',                     action:this.isBoss },
      '/private/admin/sys/backup_restore':          { component: 'page-backup-restore',               title:'Sauvegarde & restauration',    action:this.isBoss },
      '/private/admin/sys/notifications':           { component: 'page-admin-notifications',          title:'Notifications',                action:this.isBoss },
      '/private/admin/sys/monitor':                 { component: 'page-admin-srv-monitor',            title:'Santé du serveur',             action:this.isBoss },
      '/private/dev':                               { component: 'page-dev-home',                     title:'Espace développeur',           action:this.isDev },
      '/private/dev/entities':                      { component: 'page-entities',                     title:'Entités',                      action:this.isBoss },
      '/private/dev/rt':                            { component: 'page-request-tracker',              title:'Request Tracker',              action:this.isBoss },
      '/private/dev/conference':                    { component: 'page-conference',                   title:'Conference',                   action:this.isDev },
      '/private/dev/conference/:uid':               { component: 'page-conference-live',              title:'Conference',                   action:this.isDev },

    }

    this.socRoutes = {
      '/private/soc':                               { component: 'page-soc-home',                     title:'Espace SOC',                   action:this.isSoc },
      '/private/soc/alert/:hash/edit':              { component: 'page-dossier-consulter',            title:'Alerte',                       action:this.isSoc },
      '/private/soc/alert/dashboard':               { component: 'page-alerts-dashboard',             title:'Dashboard',                    action:this.isSoc },
      '/private/soc/settings':                      { component: 'page-soc-settings-home',            title:'Paramètres',                   action:this.isSoc },
      '/private/soc/settings/countermeasures':      { component: 'page-soc-countermeasures',          title:'Contre mesures',               action:this.isSoc },
      '/private/soc/settings/classifications':      { component: 'page-soc-classifications',          title:'Classifications',              action:this.isSoc },
    }

    // because we can not specify title of the webapp while installing it on IOS
    if (ENV === 'dev') {
      this.authenticatedRoutes['/private/doc/faq'].title = 'SysDreamIO DD';
    } else if (ENV === 'preprod') {
      this.authenticatedRoutes['/private/doc/faq'].title = 'SysDreamIO PP';
    } else {
      this.authenticatedRoutes['/private/doc/faq'].title = 'SysDreamIO';
    }

    this.buildRoutes();
    window.addEventListener('session-refreshed', this.buildRoutes);
  }

  async buildRoutes() {

    if (this.routes) return;

    let routes = this.menuToRoutes(this.publicRoutes);
    routes.animate = true;

    if (Session.isAuthenticated()) {
      await import(/* webpackChunkName: "authed" */ 'Components/authenticated/index.js');
      routes = routes.concat(this.menuToRoutes(this.authenticatedRoutes));
    }

    if (Session.isBoss()) {
      await import(/* webpackChunkName: "boss" */ 'Components/boss/index.js');
      routes = routes.concat(this.menuToRoutes(this.adminRoutes));
    }

    if (Session.isSoc()) {
      await import(/* webpackChunkName: "soc" */ 'Components/boss/index.js');
      routes = routes.concat(this.menuToRoutes(this.socRoutes));
    }

    // redirect / to /public
    routes.unshift({ path: '/', redirect: '/public' });

    // handle 404
    routes.push({ path: '(.*)', title: 'Page non trouvée', action: (ctx, cmd) => {
      if (ctx.pathname.includes('admin')) {
        return cmd.component('error-forbidden');
      }
      return cmd.component('error-not-found');
    } });

    this.routes = routes;
    this.router.setRoutes(this.routes);
  }


  menuToRoutes(menu, parentPath  = '') {
    return Object.entries(menu).flatMap(([path, item]) => {
      const fullPath = parentPath+path;
      const route = { path: fullPath };
      if (item.title) route.title = item.title;
      if (item.component) route.component = item.component;
      if (item.redirect) route.redirect = item.redirect;
      if (item.action) route.action = item.action;
      route.animate = true;

      if (item.pages) {
        const childRoutes = this.menuToRoutes(item.pages, fullPath);
        return [route, ...childRoutes];
      } else {
        return [route];
      }
    });
  }

  isAuthenticated(ctx, cmd) {
    if (!Session.isAuthenticated()) {
      return cmd.component('error-forbidden');
    }
    return true;
  }

  isBoss(ctx, cmd) {
    if (!Session.isAuthenticated() || !Session.isBoss()) {
      return cmd.component('error-forbidden');
    }
    return true;
  }


  isSoc(ctx, cmd) {
    if (!Session.isAuthenticated() || !Session.isSoc()) {
      return cmd.component('error-forbidden');
    }
    return true;
  }

  isDev(ctx, cmd) {
    if (!Session.isAuthenticated() || !Session.isBoss()) {
      return cmd.component('error-forbidden');
    }
    return true;
  }

}

export default RoutesManager;
