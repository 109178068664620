import App from 'App';

/*
function escapeHtml(html) {
  const div = document.createElement('div');
  div.textContent = html;
  return div.innerHTML.replace(/\n/g, '<br>');
}
*/

function notify(message, variant = 'primary', icon = 'check', duration = 5000) {
  message = message.replace(/\n/g, '<br/>');
  
  const alert = Object.assign(document.createElement('sl-alert'), {
    closable: true,
    variant,
    duration,
    innerHTML: `<m-icon slot="icon" name="${icon}" style="font-size:40px;"></m-icon>${message}`
  });
  
  document.body.append(alert);
  
  alert.addEventListener('click', (ev) => {
    if (ev.target.tagName !== 'SL-ALERT') {
      alert.duration = Infinity;
      alert.setAttribute('duration', Infinity);
    }
  });

  return alert.toast();
}

function errorCode(id, url, detail) {
  if (!codes[id]) id = 'DEFAULT';
  const code = codes[id];
  if (code.nopopup) return;

  let msg = '<alert-message ';
  msg+=` title="${code[App.config.language].title}"`;
  msg+=` message="${code[App.config.language].text || ''} ${detail || ''}"`
  if (url) msg+=` url="${url}"`;
  msg+='></alert-message>';
  notify(msg, 'danger', 'bug_report', code.duration || 100000);
}

function success(opts) {
  let msg = '<alert-message ';
  if (opts.title) msg+=` title="${opts.title}"`;
  if (opts.message) msg+=` message="${opts.message}"`
  if (opts.url) msg+=` url="${opts.url}"`;
  msg+='></alert-message>';
  notify(msg, opts.style || 'primary', opts.icon || 'check', opts.duration || 5000);
}


const codes = {
  'ENETWORK_ERROR': {
    french:{
      title:'Une erreur réseau est survenue.',
      text:'Merci de vérifier votre connexion internet et de réessayer ultérieurement.',
      help:'Apparaît lorsque l\'application n\'arrive pas à joindre le serveur pendant un call API'
    },
    english:{
      title:'A network error has occurred.',
      text:'Please check your internet connection and try again later.',
      help:'Appears when the application cannot reach the server during an API call'
    }
  },
  'EHTTP_404': {
    french:{
      title:'Une erreur 404 (introuvable) est survenue.',
      text:'Merci de réessayer ultérieurement.',
      help:'Apparaît lorsque le serveur retourne une erreur 404'
    },
    english:{
      title:'A 404 error (not found) has occurred.',
      text:'Please try again later.',
      help:'Appears when the server returns a 404 error'
    }
  },
  'EHTTP_405': {
    french:{
      title:'Une erreur 405 (méthode non autorisée) est survenue.',
      text:'Merci de réessayer ultérieurement.',
      help:'Apparaît lorsque le serveur a refusé la méthode HTTP utilisée'
    },
    english:{
      title:'A 405 error (method not allowed) has occurred.',
      text:'Please try again later.',
      help:'Appears when the server refused the HTTP method used'
    }
  },
  'EHTTP_504': {
    french:{
      title:'Une erreur 504 (gateway timeout) est survenue.',
      text:'Merci de réessayer ultérieurement.',
      help:'Apparaît lorsque le serveur n\'est pas joignable ou a crashé'
    },
    english:{
      title:'A 504 error (gateway timeout) has occurred.',
      text:'Please try again later.',
      help:'Appears when the server is not reachable or has crashed'
    }
  },
  'ETOKEN': {
    french:{
      title:'Erreur de sécurité',
      text:'Token CSRF incorrecte, merci de réessayer.',
      help:'Apparaît lorsque le token CSRF est incorrecte'
    },
    english:{
      title:'Security error',
      text:'Incorrect CSRF token, please try again.',
      help:'Appears when the CSRF token is incorrect'
    }
  },
  'ETOKEN_SSO_NOT_ACTIVE':{
    nopopup:true,
    french:{
      title:'Session expirée',
      text:'Votre session a expirée, veuillez vous reconnecter.',
      help:'Apparaît lorsque la session a expirée'
    },
    english:{
      title:'Session expired',
      text:'Your session  has expired, please log in again.',
      help:'Appears when the session has expired'
    }
  },
  'ETOKEN_SESSION_NOT_ACTIVE':{
    nopopup:true,
    french:{
      title:'Session expirée',
      text:'Votre session a expirée, veuillez vous reconnecter.',
      help:'Apparaît lorsque la session a expirée'
    },
    english:{
      title:'Session expired',
      text:'Your session  has expired, please log in again.',
      help:'Appears when the session has expired'
    }
  },
  'EREFRESH_TOKEN_ERROR': {
    french:{
      title:'Une erreur est survenue pendant le rafraîchissement de votre session.',
      text:'Merci de réessayer ultérieurement.',
      help:'Apparaît lorsque qu\'un problème est survenu pendant le rafraîchissement du token JWT'
    },
    english:{
      title:'An error occurred while refreshing your session.',
      text:'Please try again later.',
      help:'Appears when a problem occurred during the refresh of the JWT token'
    }
  },
  'EVALIDATION_FAILED': {
    french:{
      title:'Une erreur de validation est survenue.',
      text:'Merci de vérifier les champs du formulaire.',
      help:'Apparaît lorsqu\'un champ du formulaire n\'est pas valide'
    },
    english:{
      title:'A validation error has occurred.',
      text:'Please check the form fields.',
      help:'Appears when a form field is not valid'
    }
  },
  'EKC_GROUP_NOT_FOUND': {
    french:{
      title:'Erreur: groupe introuvable',
      text:'',
      help:'Apparaît lorsque le groupe n\'a pas été trouvé côté keycloak'
    },
    english:{
      title:'Error: group not found',
      text:'Please check the group code.',
      help:'Appears when the group code was not found keycloak side'
    }
  },
  'EKC_PROTECTED_GROUP': {
    french:{
      title:'Erreur: groupe protégé',
      text:'Ce groupe est protégé et ne peut être supprimé.',
      help:'Apparaît lorsque le groupe est protégé et ne peut être supprimé'
    },
    english:{
      title:'Error: protected group',
      text:'This group is protected and cannot be deleted.',
      help:'Appears when the group is protected and cannot be deleted'
    }
  },
  'EIAM': {
    french:{
      title:'Erreur IAM inattendue',
      help:'Apparaît lorsque une erreur IAM inattendue est survenue'
    },
    english:{
      title:'Unexpected IAM error',
      help:'Appears when an unexpected IAM error occurred'
    }
  },
  'ENOTIFICATION_ALREADY_SUBSCRIBED': {
    french:{
      title:'Erreur: déjà abonné à ce canal',
      text:'Vous êtes déjà abonné à ce canal de notification.',
      help:'Apparaît lorsque l\'utilisateur est déjà abonné à un canal de notification'
    },
    english:{
      title:'Error: already subscribed to this notification channel.',
      text:'You are already subscribed to this notification channel.',
      help:'Appears when the user is already subscribed to a notification channel'
    }
  },
  'ETICKET_ALREADY_OPEN': {
    french:{
      title:'Erreur: ticket déjà ouvert',
      text:'Ce ticket est déjà ouvert.',
      help:'Apparaît lorsque le ticket est déjà ouvert'
    },
    english:{
      title:'Error: ticket already open',
      text:'This ticket is already open.',
      help:'Appears when the ticket is already open'
    }
  },
  'ETICKET_NOT_FOUND': {
    french:{
      title:'Erreur: ticket introuvable',
      text:'Ce ticket est introuvable.',
      help:'Apparaît lorsque le ticket n\'a pas été trouvé'
    },
    english:{
      title:'Error: ticket not found',
      text:'This ticket was not found.',
      help:'Appears when the ticket was not found'
    }
  },
  'ETICKET_INVALID_HASH': {
    french:{
      title:'Erreur: hash ticket invalide',
      text:'Ce ticket est invalide.',
      help:'Apparaît lorsque le hash du ticket est invalide'
    },
    english:{
      title:'Error: invalid hash ticket',
      text:'This ticket is invalid.',
      help:'Appears when the ticket hash is invalid'
    }
  },
  'ERT_UNREACHABLE': {
    french:{
      title:'Erreur: RT injoignable',
      text:'Request Tracker est injoignable.',
      help:'Apparaît lorsque Request Tracker n\'est pas joignable'
    },
    english:{
      title:'Error: RT unreachable',
      text:'Request Tracker is unreachable.',
      help:'Appears when Request Tracker is not reachable'
    }
  },
  'ERT_BAD_TOKEN': {
    french:{
      title:'Error: token RT invalide',
      text:'Token RT invalide.',
      help:'Apparaît lorsque le token RT est invalide'
    },
    english:{
      title:'Error: invalid RT token',
      text:'Invalid RT token.',
      help:'Appears when the RT token is invalid'
    }
  },
  'ERT_USER_NOT_FOUND': {
    french:{
      title:'Erreur: RT: Utilisateur introuvable',
      text:'Votre compte est introuvable dans Request Tracker',
      help:'Apparaît lorsque l\'utilisateur n\'a pas été trouvé dans Request Tracker'
    },
    english:{
      title:'Error: RT: User not found',
      text:'Your account was not found in Request Tracker',
      help:'Appears when the user was not found in Request Tracker'
    }
  },
  'ERT_USER_QUEUE_NOT_ASSOCIATED': {
    french:{
      title:'Erreur: RT: Utilisateur non associé à la file',
      text:'Votre compte n\'est pas associé à la file de tickets dans Request Tracker',
      help:'Apparaît lorsque l\'utilisateur n\'est pas associé à la file de tickets dans Request Tracker'
    },
    english:{
      title:'Error: RT: User not associated to queue',
      text:'Your account is not associated to the ticket queue in Request Tracker',
      help:'Appears when the user is not associated to the ticket queue in Request Tracker'
    }
  },
  'ETICKET_ALREADY_RESOLVED_FP': {
    french:{
      title:'Alerte déjà résolue (faux positif)',
      text:'Cette alerte est déjà résolue (faux positif).',
      help:'Apparaît lorsque l\'alerte est déjà été résolue en faux positif'
    },
    english:{
      title:'Alert already resolved (false positive)',
      text:'This alert is already resolved (false positive).',
      help:'Appears when the alert is already resolved as a false positive'
    }
  },
  'ETICKET_CM_ALREADY_EXISTS': {
    french:{
      title:'Contre-mesure déjà existante',
      text:'Cette contre-mesure est déjà existante.',
      help:'Apparaît lorsque la contre-mesure est déjà existante'
    },
    english:{
      title:'Countermeasure already exists',
      text:'This countermeasure already exists.',
      help:'Appears when the countermeasure already exists'
    }
  },
  'ETICKET_CM_DOES_NOT_EXISTS': {
    french:{
      title:'Contre-mesure inexistante',
      text:'Cette contre-mesure est inexistante.',
      help:'Apparaît lorsque la contre-mesure est inexistante'
    },
    english:{
      title:'Countermeasure does not exist',
      text:'This countermeasure does not exist.',
      help:'Appears when the countermeasure does not exist'
    }
  },
  'ETICKET_CM_DOES_NOT_BELONG':{
    french:{
      title:'Contre-mesure non associée à cet incident',
      text:'Cette contre-mesure n\'est pas associée à cet incident.',
      help:'Apparaît lorsque la contre-mesure n\'est pas associée à l\'incident correspondant'
    },
    english:{
      title:'Countermeasure not associated to this incident',
      text:'This countermeasure is not associated to this incident.',
      help:'Appears when the countermeasure is not associated to the corresponding incident'
    }
  },
  'EFILE_NOT_FOUND':{
    french:{
      title:'Erreur: fichier introuvable',
      text:'Le fichier est introuvable.',
      help:'Apparaît lorsque le fichier demandé n\'existe pas'
    },
    english:{
      title:'Error: file not found',
      text:'The file is not found.',
      help:'Appears when the requested file does not exist'
    }
  },
  'ECROND_ERROR':{
    french:{
      title:'Une erreur est survenue dans le traîtement de la tâche',
      help:'Apparaît lorsque une erreur est survenue dans le traîtement de la tâche'
    },
    english:{
      title:'An error occurred in the task processing',
      help:'Appears when an error occurred in the task processing'
    }
  },
  'EITEM_NOT_FOUND':{
    french:{
      title:'Erreur: élément introuvable',
      text:'L\'élement est introuvable.',
      help:'Apparaît lorsque l\'élement demandé n\'existe pas'
    },
    english:{
      title:'Error: item not found',
      text:'The item is not found.',
      help:'Appears when the requested item does not exist'
    }
  },
  'ETRANSLATE_ENGINE_UNREACHABLE': {
    french:{
      title:'Erreur: le moteur de traduction injoignable',
      text:'Le moteur de traduction est injoignable.',
      help:'Apparaît lorsque le moteur de traduction est injoignable'
    },
    english:{
      title:'Error: the translation engine unreachable',
      text:'The translation engine is unreachable.',
      help:'Appears when the translation engine is unreachable'
    }
  },
  'DEFAULT': {
    french:{
      title:'Une erreur inconnue est survenue.',
      text:'Merci de réessayer ultérieurement.',
      help:'Message d\'erreur générique utilisé lorsqu\'aucun code d\'erreur envoyé par le serveur ne correspond'
    },
    english:{
      title:'An unknown error has occurred.',
      text:'Please try again later.',
      help:'Generic error message used when no error code sent by the server matches'
    }
  }
}

export default {
  success: success,
  info: (message, duration = 5000) => notify(message, 'primary', 'info', duration),
  error: (message, duration = 10000) => notify(message, 'danger', 'bug_report', duration),
  errorCode,
  codes
}