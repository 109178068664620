async function loadApexCharts() {
  const version = '3.54.0';
  const ext = ENV === 'dev' ? 'js' : 'min.js';
  return new Promise((resolve, reject) => {
    const jsLoaded = !!document.querySelector(`script[src="assets/vendors/apexcharts/${version}/dist/apexcharts.${ext}"]`);
    const cssLoaded = !!document.querySelector(`link[href="assets/vendors/apexcharts/${version}/dist/apexcharts.css"]`);

    // Charger le JS s'il n'est pas encore chargé
    if (!jsLoaded) {
      const script = document.createElement('script');
      script.src = `assets/vendors/apexcharts/${version}/dist/apexcharts.${ext}`;
      script.type = 'text/javascript';
      script.async = true;
      script.onload = () => { resolve(); }
      script.onerror = () => reject(new Error('ApexCharts JS failed to load.'));
      document.head.appendChild(script);
    } else {
      // perhaps tags has been inserted but not yet loaded
      // so we wait for it to be loaded
      const interval = setInterval(() => {
        if (window.ApexCharts) {
          clearInterval(interval);
          resolve();
        }
      }, 100);
    }

    // Charger le CSS s'il n'est pas encore chargé
    if (!cssLoaded) {
      const link = document.createElement('link');
      link.href = `assets/vendors/apexcharts/${version}/dist/apexcharts.css`;
      link.rel = 'stylesheet';
      document.head.appendChild(link);
    }
  });
}

async function loadCherryMarkdown() {
  const version = '0.8.49';
  const ext = ENV === 'dev' ? 'js' : 'min.js';
  let link;
  return new Promise((resolve, reject) => {
    const jsLoaded = !!document.querySelector(`script[src="assets/vendors/cherry-markdown/${version}/dist/cherry-markdown.core.${ext}"]`);
    const cssLoaded = !!document.querySelector(`link[href="assets/vendors/cherry-markdown/${version}/dist/cherry-markdown.min.css"]`);

    // Charger le JS s'il n'est pas encore chargé
    if (!jsLoaded) {
      const script = document.createElement('script');
      script.src = `assets/vendors/cherry-markdown/${version}/dist/cherry-markdown.${ext}`;
      script.type = 'text/javascript';
      script.async = true;
      script.onload = () => { wait() }
      script.onerror = () => reject(new Error('CherryMarkdown JS failed to load.'));
      document.head.appendChild(script);
    } else {
      // perhaps tags has been inserted but not yet loaded
      // so we wait for it to be loaded
      const interval = setInterval(() => {
        if (window.Cherry) {
          clearInterval(interval);
          console.log('ici',link);
          resolve();
        }
      }, 1000);
    }

    // Charger le CSS s'il n'est pas encore chargé
    if (!cssLoaded) {
      link = document.createElement('link');
      link.href = `assets/vendors/cherry-markdown/${version}/dist/cherry-markdown.min.css`;
      link.rel = 'stylesheet';
      link.onload = (e) => {
        console.log('css loaded', e);
      }
      document.head.appendChild(link);
    }

    function wait() {
      const interval = setInterval(() => {
        if (window.Cherry) {
          clearInterval(interval);
          console.log('ici',link);
          resolve();
        }
      }, 1000);
    }
  });
}

export default {
  loadApexCharts,
  loadCherryMarkdown,
}