import App from 'App';

function lookup(record, fieldname, lg, fallback = true) {
  lg = lg || App.config.lg || App.config.lgMain;
  if (!record) return '';
  if (!record[fieldname]) return '';
  
  if (typeof record[fieldname] === 'string') return record[fieldname];

  let value = record[fieldname][lg] || '';
  if (!value && fallback) value =record[fieldname][App.config.lgMain] || '';
  //console.log('lookup', value);
  return value;
}

export default {
  lookup,
  mainlg:'fr'
};
